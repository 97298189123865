.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.5s ease;
}

.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}

.accordion-content {
  max-height: 500px;
}